
import 'slick-carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if ($('.slider-stage').length) { 
    $('.slider-stage').not('.slick-initialized').slick();
}

function init() {
    if ($('.slider-container-desktop-content').length) {
        $('.slider-container-desktop-content').slick({
            dots: true,
            prevArrow: '.slider-desktop-prev-icon',
            nextArrow: '.slider-desktop-next-icon'
        });
    }

    if ($('.slider-container-mobile-content').length) {
        $('.slider-container-mobile-content').slick({
            dots: true,
            prevArrow: '.slider-mobile-prev-icon',
            nextArrow: '.slider-mobile-next-icon'
        });
    }

    if ($('.slider-stage').length) { 
        $('.slider-stage').not('.slick-initialized').slick();
    }

};

window.addEventListener('load', init);

